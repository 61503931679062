<!--
 * @Description: 产品介绍
 * @version: v1.0.0
 * @Author: ladd
 * @Date: 2021-08-27 11:08:06
 * @LastEditors: ladd
 * @LastEditTime: 2021-09-07 11:01:49
-->
<template>
  <div class='productInfo'  v-loading="loading"  element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
    <h6 class="title">产品介绍</h6>
    <div class="wrap">
      <div class="productTitle">微信银行码</div>
      <p class="info">银行码可帮助银行推广自己绑卡业务，具有灵活免开发的特点，可有效降低银行线下运营门槛，促进银行线下网点的业务转化。</p>
      <div class="navList">
        <el-row>
          <el-col :span="4" v-for="(item, index) in navArr" :key="index">
            <div class="navItem">
              <el-image
              style="width: 30px; height: 30px"
              :src="item.icon"
              fit="fit"></el-image>
              <p class="name">{{item.name}}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      
      <div class="footer">
         <el-button  v-role="'CodeSet'" type="primary" @click="toConfig">立即配置</el-button>
         <el-button  v-role="'CodeExp'" @click="batchImport">批量导入</el-button>
      </div>
    </div>
    <el-dialog
      title="批量导入银行码"
      :visible.sync="outerVisible"
      :destroy-on-close="true"
      width="50%">
      <el-dialog
        width="30%"
        title="导入结果"
        :visible.sync="innerVisible"
        append-to-body>
        <p>共{{result.success + result.fail}}条数据，成功导入{{result.success}}条，导入失败{{result.fail}}条</p>
        <div class="uploadTemplate"  v-if="result.fail> 0" @click="downFailData()">下载导入失败数据</div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="getIt">关闭</el-button>
        </span>
      </el-dialog>
      <div class="uploadWrap">
        <el-upload
          class="_el-upload"
          action=""
          :on-change="file => handleUploadFile(file)"
          :on-remove="handleRemove"
          :file-list="fileList"
          :auto-upload="false">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div class="uploadTxt">
          <span class="label">请上传数据文件</span>
          <a href="https://cdn.wecloudx.com/bank_code_template1630490733742.xlsx" class="uploadTemplate">下载导入模板</a>
        </div>
      </div>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadFile">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as bankCodeSet from '@/api/bankCodeSet'
import * as Public from '@/api/public'
export default {
  //import引入的组件需要注入到对象中才能使用
  name: 'ProductInfo',
  components: {},
  data() {
    //这里存放数据
    return {
      loading: false,
      outerVisible: false,
      innerVisible: false,
      navArr: [
        {
          id: 1, icon: require('@/assets/images/u20.png'), name: '免开发'
        },{
          id: 2, icon: require('@/assets/images/u24.png'), name: '便捷配置'
        },{
          id: 3, icon: require('@/assets/images/u28.png'), name: '多样渠道'
        },{
          id: 4, icon: require('@/assets/images/u32.png'), name: '数据清晰'
        }],
      fileList: [],
      result: {
        success: 0,
        fail: 0
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 立即配置
    toConfig: function(){
      this.$emit('toConfig', 1)
    },
    // 批量导入弹窗
    batchImport: function(){
      this.outerVisible = true;
    },
    // 移除
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //上传
    handleUploadFile(file) {
      this.file = file;
      
    },
    // 确定上传
    uploadFile: function(){
      let formData = new FormData()
      if (this.file) {
        formData.append('file', this.file.raw)
        this.loading = true;
        bankCodeSet.importFile(formData).then(res => {
          this.loading = false;
          this.result = {
            success: res.success.length,
            fail: res.fail.length,
            hash: res.hash
          }
          this.innerVisible = true
        })
      } 
      
    },
    // 下载失败数据
    downFailData: function(){
       const {hash} = this.result
      // bankCodeSet.downFile({fail_filename, app_name}).then(res => {
      //   if(res.file_hash) {
        Public.downloadData({file_hash: hash})
        // }
      // })
    },
    // 我知道了
    getIt: function(){
      this.innerVisible = false;
      this.outerVisible = false;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.uploadTemplate{
  color: #22ac38;
  cursor: pointer;
}
.productInfo{
  padding-top: 50px;
  .title{
    font-size: 18px;
  }
  .wrap{
    padding: 50px;
    .productTitle,
    .info{
      line-height: 48px;
    }
    .navList{
      width: 60%;
      margin-top: 20px;
      .navItem{
        display: flex;
        flex-direction: column;
        align-items: center;
        .name{
          line-height: 30px;
        }
      }
    }
    .footer{
      margin-top: 100px;
      display: flex;
      justify-content: center;
    }
  }
  .uploadWrap{
    display: flex;
    .uploadTxt{
      margin-left: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      
    }
  }
}
</style>